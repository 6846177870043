@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  font-family: "Rubik", sans-serif;
}

a {
  text-decoration: none;
}

.color-blue {
  color: #7e98df;
}

.bg-blue {
  background-color: #7e98df;
}

.bg-blue:hover {
  background-color: #587ad8;
}

.profile-rounded {
  border-radius: 16px;
}

.back-icon {
  position: absolute;
  left: -5px;
}

.back-icon:hover {
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}
