.left-menu {
  background-color: #fff;
  height: 100vh;
  overflow: auto;
  border-right: 2px solid #e5e5e5;
}

.hamburger-item {
  height: 5px;
  width: 35px;
  background-color: #7e98df;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dropdown-menu {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #7e98df;
}

.dropdown-item:hover {
  background-color: #7e98df;
}

.input-search {
  border-radius: 10px;
}

.user-item {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  margin-bottom: 3px;
}

.user-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.chat-menu {
  height: 100vh;
}

.chat-menu-blank {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.chat-menu-header img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.chat-menu-message {
  flex: 1;
  overflow: auto;
}

.chat-menu-message img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.ballon-left {
  background-color: #7e98df;
  padding: 20px;
  max-width: 500px;
  color: white;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

.ballon-right {
  background-color: #fff;
  padding: 20px;
  max-width: 500px;
  color: black;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}

.profile {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profile img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 25px;
}

.edit-icon {
  bottom: 0px;
  right: -5px;
  font-size: 22px;
}

.container-alert {
  margin-top: -75px;
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  height: 0px;
  border-radius: 0 0 10px 10px;
  color: #fff;
}

#note {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  background: #7e98df;
  opacity: 0.8;
  text-align: center;
  line-height: 2.5;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px black;
  -moz-box-shadow: 0 0 5px black;
  box-shadow: 0 0 5px black;
  transform: translateY(-50px);
  animation: slideDown 0.2s 0.5s 1 ease forwards;
  transition: 0.5s;
}

@keyframes slideDown {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(90px);
  }
}
